angular.module('common-app').config(function ($httpProvider){
    var csrf = $('meta[name="csrf-token"]').attr("content");

    var headers = $httpProvider.defaults.headers;

    'post|put|delete'.split('|').map(function(method) {
        (headers[method] = headers[method] || {})['X-CSRF-Token'] = csrf;
    })

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
});